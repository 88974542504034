.noImage {
  width: 100%;
  aspect-ratio: 1;
  background-color: var(--box-bg);
  border-radius: 10px;
}

.noImage::after {
  content: "?";
  height: 100%;
  width: 100%;
  font-size: 5em;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
}

.image {
  object-fit: cover;
  max-height: 100%;
  border-radius: 10px;
}
