.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  background-color: var(--box-bg);
  padding: 20px;
  border-radius: 20px;
  max-width: 270px;
}

.container h3,
.container h4 {
  background-color: var(--box-bg);
  padding: 5px 10px;
  border-radius: 10px;
}

.deployButton {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.container label {
  padding: 0 4px;
  font-size: 12px;
  line-height: 1.5;
}
