.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 50px;
  width: 100%;
}

/* @media (max-width: 800px) {
  .container {
    flex-direction: column;
  }
} */
