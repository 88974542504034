.landingCard {
  background-color: var(--box-bg);
  padding: 30px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  border-radius: 20px;
}

.landingCard p {
  font-size: 24px;
  line-height: 1.5;
}

.connectButton {
  font-size: 24px;
  width: 100%;
}

.demoCard {
  background-color: var(--box-bg);
  border: 2px solid var(--button-border);
  padding: 10px;
  width: 80%;
  max-width: 350px;
  border-radius: 20px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  transform: translateX(10%);
  box-shadow: 1px 1px 4px 0px rgba(157, 212, 0, 0.2);
}

.mech {
  position: absolute;
  bottom: -14px;
  left: -35%;
  height: 100%;
}

.demoInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 45%;
  font-family: monospace;
  font-size: 12px;
}

.demoInfo li {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.demoInfo label {
  background-color: var(--box-bg);
  border-radius: 10px;
  padding: 5px 10px;
  width: fit-content;
  transform: translateX(-30px);
}
.demoInfo .infoItem {
  background-color: var(--box-bg);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 4px;
  box-shadow: 1px 1px 4px 0px rgba(157, 212, 0, 0.2);
}

.demoInfo p {
  font-size: 12px;
  font-family: monospace;
}

.infoItem img {
  height: 20px;
}

.operator {
  align-items: start;
}

.operator > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.operator p {
  background-color: var(--box-bg);
  border-radius: 10px;
  padding: 2px 4px;
  text-align: right;
  display: inline-block;
}
.operator img {
  height: 60px;
  border-radius: 5px;
}

.demoInfo .infoItem.address {
  font-size: 1em;
  justify-content: center;
}
