.layout {
  display: flex;
  flex-direction: column;
  gap: 50px;
  min-height: 100vh;
  max-width: 2000px;
  margin: 0 auto;
}

.headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 2000px;
  padding: 20px;
}

.buttonGroup {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.cockpit {
  position: fixed;
  z-index: 1;
  width: 45%;
  max-width: 1000px;
  top: 30px;
  left: 10px;
  mix-blend-mode: color-burn;
  opacity: 0.35;
  pointer-events: none;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 150px 0 50px;
  position: relative;
  z-index: 10;
  padding: 0 20px;
}

.nav {
  display: flex;
  gap: 1rem;
  flex-grow: 1;
}

.nav h1 {
  font-size: 36px;
  background-color: var(--box-bg);
  border-radius: 20px;
  padding: 5px 10px;
}

.nav a {
  text-decoration: none;
  color: var(--text-color);
}

.nav a:hover {
  filter: brightness(1.2);
}

.accountButtons {
  position: relative;
}

.accountButtons:hover .viewAccountContainer {
  display: block;
}

.viewAccountContainer {
  display: none;
  position: absolute;
  bottom: -120%;
  right: 0;
  z-index: 90;
  padding-top: 30%;
}

button.viewAccount {
  background: rgb(248 212 226);
  border-radius: 10px;
  padding: 6px 12px;
  border: 2px solid var(--button-bg);
  box-shadow: 2px 2px 10px 0px rgba(150, 203, 0, 0.44);
}

button.viewAccount:hover {
  background-color: var(--button-bg);
  filter: brightness(1);
}
