.linkContainer {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  aspect-ratio: 1/1;
  position: relative;
  text-decoration: none;
  color: var(--text-color);
  border: 2px solid transparent;
  overflow: hidden;
}

.linkContainer:hover {
  border: 2px solid var(--button-border);
}

.header,
.footer {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 10px;
  z-index: 10;
  width: calc(100% - 20px);
}

.header p {
  display: inline-block;
  border-radius: 10px;
  background-color: rgb(246 241 212);
  padding: 2px 8px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}

.header {
  top: 10px;
}

.footer {
  display: none;
  bottom: 10px;
}

.deployStatus {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 10px;
  background-color: rgb(246 241 212);
  padding: 2px 8px;
  font-style: italic;
  font-size: 12px;
}

.indicator {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--button-border);
  filter: blur(4px);
}

.indicator.undeployed {
  background-color: rgb(189 192 179);
}

.chain {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: rgb(246 241 212);
  padding: 2px 8px;
}
.chain svg {
  width: 15px;
  height: 15px;
}

.tokenId {
  max-width: 30%;
  font-family: monospace;
}

.tokenId.collectionTokenId {
  max-width: 100%;
}

.tokenName {
  max-width: 68%;
}

.tokenName a {
  color: inherit;
  text-decoration: inherit;
}

.main {
  aspect-ratio: 1;
}

.imageContainer {
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
}

.main .image {
  object-fit: cover;
  max-height: 100%;
  border-radius: 10px;
}

.noImage {
  height: 100%;
}

.noImage::after {
  content: "?";
  height: 100%;
  width: 100%;
  font-size: 5em;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}

.linkContainer:hover .footer {
  display: flex;
}

.linkContainer:hover .visit {
  display: flex;
}

.visit {
  position: absolute;
  inset: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(241 255 203 / 65%);
}

.visit h3 {
  font-size: 1.5em;
  color: var(--button-border);
  mix-blend-mode: exclusion;
}
