.button {
  height: 40px;
  padding: 5px 10px;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: none;
}

.button:hover {
  background-color: var(--button-bg);
  filter: none;
}

.chevronDown {
  stroke: var(--text-color);
  width: 1em;
  padding-top: 3px;
}
.chevronDown.up {
  transform: rotate(180deg);
}

.icon {
  height: 100%;
}

.dropdownIcon {
  height: 100%;
  width: 15px;
}

.container {
  position: relative;
}

.dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  background: rgb(248 212 226);
  border-radius: 10px;
  padding: 6px;
  min-width: 200px;
  z-index: 100;
  border: 2px solid var(--button-bg);
  box-shadow: 2px 2px 10px 0px rgba(150, 203, 0, 0.44);
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dropdownItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  background: none;
  border: none;
  color: var(--text-color);
  padding: 10px 8px;
  border-radius: 10px;
}

.dropdownItem:hover {
  background-color: var(--button-bg);
}

.chain {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkmark {
  background-color: var(--box-bg);
  padding: 2px 4px;
  border-radius: 100px;
  stroke: var(--text-color);
}
