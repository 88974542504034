.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.categoryContainer {
  width: 100%;
  max-width: 520px;
}

.category {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--box-bg);
  padding: 10px 15px 10px 10px;
  border-radius: 10px;
  width: fit-content;
}

.noNfts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: var(--box-bg);
  padding: 20px;
  font-size: 12px;
}

.pageButtons {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}
