.button {
  background-color: var(--button-bg);
  border: 2px solid var(--button-border);
  color: var(--button-text);
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 2px 2px 10px 0px rgba(150, 203, 0, 0.44);
  transition: filter 0.1s;
}

.secondary {
  border-color: var(--button-bg);
  background-color: rgb(192 255 12 / 20%);
}

.button:hover {
  filter: brightness(1.2);
}
.button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  filter: none;
}
