.searchBar {
  flex-grow: 1;
  max-width: 500px;
  background-color: var(--box-bg);
  border-radius: 20px;
  position: relative;
}

.magnifyingGlass {
  height: 1.8em;
  stroke: var(--text-color);
  position: absolute;
  left: 12px;
  top: 12px;
}

.input {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1em;
  padding: 5px 10px;
  padding-left: 50px;
  border-radius: 20px;
  border: 2px solid transparent;
  font-family: monospace;
}

.input:focus {
  outline: none;
  border: 2px solid var(--button-border);
}
.input::placeholder {
  color: var(--text-color);
  opacity: 0.8;
}

.invalidSearch {
  position: absolute;
  bottom: -14px;
  right: 20px;
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  width: fit-content;
  color: var(--text-color);
  border: 1px solid var(--text-color);
  font-size: 12px;
  padding: 3px 8px;
  padding-left: 4px;
  border-radius: 20px;
  background-color: rgb(255, 195, 213);
}

.invalidSearch p {
  font-weight: bold;
  font-style: italic;
}

.warning {
  font-weight: bold;
  /* font-style: italic; */
  aspect-ratio: 1;
  width: 1.5em;
  background-color: var(--text-color);
  color: white;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.results {
  display: flex;
  width: 100%;
  position: absolute;
  top: calc(100% + 20px);
  background: rgb(248 212 226);
  border-radius: 20px;
  padding: 20px 10px 10px;
  z-index: 100;
  border: 2px solid var(--button-bg);
  box-shadow: 2px 2px 10px 0px rgba(150, 203, 0, 0.44);
}
