.itemContainer {
  border-radius: 20px;
  background-color: var(--box-bg);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.header p {
  display: inline-block;
  border-radius: 10px;
  background-color: var(--box-bg);
  padding: 2px 8px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  font-size: 24px;
}

.tokenId {
  max-width: 30%;
  font-family: monospace;
}

.tokenName {
  max-width: 68%;
  color: var(--text-color);
}
.tokenName:hover {
  filter: brightness(1.2);
}

.main {
  height: 63%;
  max-height: 360px;
  display: flex;
  justify-content: space-between;
}
.info {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.itemContainer label {
  background: var(--box-bg);
  border-radius: 7px;
  padding: 5px 10px;
  width: fit-content;
  font-size: 12px;
}

.info .infoItem {
  background-color: var(--box-bg);
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding: 5px 7px;
  box-shadow: 1px 1px 4px 0px rgba(157, 212, 0, 0.2);
  font-family: monospace;
  font-size: 12px;
}

.info li {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  background-color: var(--box-bg);
  padding: 5px 5px;
  border-radius: 10px;
  box-shadow: 1px 1px 4px 0px rgba(157, 212, 0, 0.2);
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  filter: blur(4px);
  background-color: rgb(189 192 179);
}

.indicator.deployed {
  background-color: var(--button-border);
}

.address {
  cursor: pointer;
  transition: filter 0.1s ease-in-out;
  max-width: 139px;
}

.address:hover {
  filter: brightness(1.2);
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}
.useButton {
  width: 100%;
}

.assetsContainer {
  background-color: var(--box-bg);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.assetsContainer.empty {
  padding: 20px;
}

.assetList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.asset {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.assetName {
  display: flex;
  gap: 10px;
  align-items: center;
}
.assetName img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.asset .value {
  display: flex;
  gap: 10px;
}

@media (max-width: 800px) {
  .info label {
    border: 2px solid var(--button-border);
    border-radius: 10px;
    padding: 5px 10px;
    width: fit-content;
    transform: translateX(-30px);
  }

  .info li {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: space-between;
    background: none;
    padding: 5px 5px;
    border-radius: 10px;
    box-shadow: none;
  }

  .info .infoItem {
    width: 100%;
  }

  .info {
    width: fit-content;
  }
}
