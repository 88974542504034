.container {
  overflow: hidden;
  border-radius: 50%;
  aspect-ratio: 1;
}

.container img {
  display: block;
  height: 100%;
}
